import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Seo from "../components/Seo";
import Layout from "../components/Layout";
import DisplayContent from "../components/DisplayContent";

import FullWidthImage from "../components/FullWidthImage";
import DisplayTitleWithImage from "../components/DisplayTitleWithImage";
import DisplayHeroVideoOrImage from "../components/DisplayHeroVideoOrImage";
import CategoryList from "../components/CategoryList";
import VideoCategoryList from "../components/VideoCategoryList";

// eslint-disable-next-line
export const CategoryPageTemplate = ({
  title,
  subtitle,
  footer,
  images,
  author,
  creationDate,
  breadcrumbs,
  options,
  body,
  helmet,
  layout,
  videos,
  header,
}) => {
  let templateName = "category-page",
    heroVideo,
    heroImage,
    smallImage,
    footerImage;

  if (!layout) layout = [];

  if (images) {
    if (images.hero) {
      heroImage = getImage(images.hero) || images.hero;
    }
    if (images.footer) {
      footerImage = getImage(images.footer) || images.footer;
    }
    if (images.smallImage) smallImage = images.smallImage;
  }

  if (videos && videos.hero) heroVideo = videos.hero;
  if (!layout.position) layout.position = "below";

  const showTitle = options.showTitle;
  const showTitlesSwapped = options.showTitlesSwapped;
  let showDisplayContentHeader = true;
  if (
    !options.showDisplayContentHeader &&
    options.showDisplayContentHeader !== undefined &&
    options.showDisplayContentHeader !== null
  ) {
    showDisplayContentHeader = false;
  }

  return (
    <div>
      {helmet || null}

      <DisplayHeroVideoOrImage
        heroVideo={heroVideo}
        heroImage={heroImage}
        title={title}
        subtitle={subtitle}
        imgPosition="center"
        showTitle={showTitle}
        showTitlesSwapped={showTitlesSwapped}
      />

      <section className={`section section--gradient ${templateName}`}>
        <div className="container">
          <div className="content">
            <DisplayTitleWithImage
              smallImage={smallImage}
              title={title}
              subtitle={subtitle}
              parent="Blog"
              parentSlug="/blog"
              showDisplayContentHeader={showDisplayContentHeader}
            />

            <DisplayContent
              id={templateName}
              body={body}
              layout={layout}
              element={
                <>
                  <br />
                  <CategoryList />
                  <VideoCategoryList />
                </>
              }
            />
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

CategoryPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string,
  subtitle: PropTypes.string,
  footer: PropTypes.string,
  images: PropTypes.shape({
    hero: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImageUrl: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    footer: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  author: PropTypes.string,
  creationDate: PropTypes.string,
  sortDate: PropTypes.string,
  flags: PropTypes.shape({
    published: PropTypes.bool,
    featured: PropTypes.bool,
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
    showSummary: PropTypes.bool,
    showTags: PropTypes.bool,
    showComments: PropTypes.bool,
    showAuthorSummary: PropTypes.bool,
    showPrevNext: PropTypes.bool,
    showPopular: PropTypes.bool,
    showRelated: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.shape({
    parent: PropTypes.string,
    parentSlug: PropTypes.string,
    grandparent: PropTypes.string,
    grandparentSlug: PropTypes.string,
  }),
  body: PropTypes.string,
  summary: PropTypes.string,
};

const CategoryPage = ({ data }) => {
  const { markdownRemark: post } = data;

  let imageSrc;
  //console.log(post.frontmatter.images);
  if (
    post.frontmatter.images &&
    post.frontmatter.images.hero &&
    post.frontmatter.images.hero.childImageSharp
  ) {
    imageSrc =
      post.frontmatter.images.hero.childImageSharp.gatsbyImageData.images
        .fallback.src;
  }
  if (!post.frontmatter.titles) post.frontmatter.titles = {};
  return (
    <Layout>
      <CategoryPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer}
        images={post.frontmatter.images}
        options={post.frontmatter.options}
        breadcrumbs={post.frontmatter.breadcrumbs}
        body={post.html}
        helmet={
          <Seo
            title={post.frontmatter.title}
            summary={post.frontmatter.summary}
            image={imageSrc}
            article={false}
          />
        }
      />
    </Layout>
  );
};

CategoryPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CategoryPage;

export const authorPageQuery = graphql`
  query CategoryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
        options {
          showTitle
          showTitlesSwapped
          showDisplayContentHeader
        }
        summary
      }
    }
  }
`;
