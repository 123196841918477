import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import { nanoid } from "nanoid";
import pluralize from "pluralize";
import DisplayContentHeader from "./DisplayContentHeader";
class CategoryListTemplate extends React.Component {
  render() {
    const { data } = this.props;
    let { edges: posts } = data.allMarkdownRemark;
    const { frontmatter: site } = data.site;
    const { edges: postCount } = data.count;

    let categoryCounter = [];

    postCount.forEach(({ node: post }) => {
      if (post.frontmatter.category) {
        if (!categoryCounter[post.frontmatter.category]) {
          categoryCounter[post.frontmatter.category] = 0;
        }
        categoryCounter[post.frontmatter.category]++;
      }
    });

    //console.log(categoryCounter);

    let columns = 12;

    if (site && site.layout && site.layout.columns) {
      columns = 12 / site.layout.columns;
    }

    // remove all un-published

    posts = posts.filter(
      (posts) =>
        categoryCounter[posts.node.frontmatter.name] &&
        (posts.node.frontmatter.flags === null ||
          (posts.node.frontmatter.flags &&
            posts.node.frontmatter.flags.published))
    );

    let featured = posts.filter(
      (posts) =>
        posts.node.frontmatter.flags && posts.node.frontmatter.flags.featured
    );

    let regular = posts.filter(
      (posts) =>
        !posts.node.frontmatter.flags ||
        (posts.node.frontmatter.flags && !posts.node.frontmatter.flags.featured)
    );

    return (
      <div className="content">
        {featured && featured.length ? (
          <>
            <DisplayContentHeader
              title={pluralize("Featured Category", featured.length)}
              weight="semibold"
              showColon={true}
              size="4"
            />
          </>
        ) : null}
        <div className="columns is-multiline">
          {featured &&
            featured.map(({ node: post }) => (
              <div className={`column is-${columns}`} key={nanoid()}>
                <DisplayContentHeader
                  title={post.frontmatter.name}
                  slug={post.fields.slug}
                  weight="normal"
                  size="4"
                />
              </div>
            ))}
        </div>
        {regular && regular.length ? (
          <>
            <DisplayContentHeader
              title={pluralize("Category", regular.length)}
              weight="semibold"
              showColon={true}
              size="4"
            />
          </>
        ) : null}
        <div className="columns is-multiline">
          {regular &&
            regular.map(({ node: post }) => (
              <div className={`column is-${columns}`} key={nanoid()}>
                <DisplayContentHeader
                  title={post.frontmatter.name}
                  slug={post.fields.slug}
                  weight="normal"
                  size="4"
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

CategoryList.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function CategoryList() {
  return (
    <StaticQuery
      query={graphql`
        query CategoryListQuery {
          allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___category] }
            filter: {
              frontmatter: {
                advanced: { templateKey: { eq: "category-post" } }
              }
            }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  name
                  flags {
                    featured
                    published
                  }
                }
              }
            }
          }
          count: allMarkdownRemark(
            sort: { order: ASC, fields: [frontmatter___category] }
            filter: {
              frontmatter: { advanced: { templateKey: { eq: "blog-post" } } }
            }
          ) {
            edges {
              node {
                frontmatter {
                  category
                }
              }
            }
          }
          site: markdownRemark(
            frontmatter: {
              advanced: {
                configKey: { eq: "site" }
                templateKey: { eq: "configuration" }
              }
            }
          ) {
            frontmatter {
              layout {
                columns
              }
            }
          }
        }
      `}
      render={(data, count) => (
        <CategoryListTemplate data={data} count={count} />
      )}
    />
  );
}
